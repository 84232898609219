import React from "react";

import { Container, Image } from "react-bootstrap";
import classNames from "classnames";

import Section from "./Section";
import ContactForm from "./ContactForm";
import SocialIcon from "./SocialIcon";

import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "react-intl";
import Link from "../LocaleLink";

import KedooImg from "../../images/logo.png";
import KedooWhiteImg from "../../images/logo-white.png";
import AbaImg from "../../images/aba.png";

const SocialContacts = ({ items }) => (
  <ul className="list-inline">
    {items.map(({ href, title, icon }, i) => (
      <li key={i} className="list-inline-item mr-2">
        <a href={href}>
          <SocialIcon icon={icon} /> {title}
        </a>
      </li>
    ))}
  </ul>
);

const Footer = React.forwardRef(
  ({ variant = "outline-light", hasSocialContacts = false }, ref) => {
    const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            copyrightTitle
            pageLinks {
              href
              title
            }
            footerLinks {
              href
              title
            }
            socialContacts {
              href
              title
              icon
            }
          }
        }
      }
    `);

    const { formatMessage } = useIntl();

    const socialContacts = site.siteMetadata.socialContacts;

    return (
      <footer className="py-2" ref={ref}>
        <Section>
          <Container>
            <div className="mb-3">
              <h2>{formatMessage({ id: "footer.contact_us.title" })}</h2>
              <ContactForm buttonVariant={variant} />
            </div>

            {hasSocialContacts ? (
              <div className="d-md-flex align-items-center justify-content-between">
                <div>
                  <h2 className="footer-logotype">
                    <Image
                      fluid
                      src={
                        variant === "outline-light" ? KedooWhiteImg : KedooImg
                      }
                      alt={site.siteMetadata.title}
                    />
                  </h2>
                  {/* <h2>{site.siteMetadata.title}</h2> */}
                </div>
                <div>
                  <h2>{formatMessage({ id: "footer.write_us.title" })}</h2>
                  <SocialContacts items={socialContacts} />
                </div>
              </div>
            ) : (
              <>
                {/* <h2>{site.siteMetadata.title}</h2> */}
                <div
                  className="d-md-flex align-items-end justify-content-between"
                  style={{ marginBottom: "50px" }}
                >
                  <div
                    className="d-md-flex align-items-center"
                    style={{ display: "flex" }}
                  >
                    <h2
                      className="footer-logotype"
                      style={{ margin: "0 50px 0 0" }}
                    >
                      <Image
                        fluid
                        src={
                          variant === "outline-light" ? KedooWhiteImg : KedooImg
                        }
                        alt={site.siteMetadata.title}
                      />
                    </h2>
                    <a href="https://aba-media.ru/ " target="_blank">
                      <Image
                        fluid
                        src={AbaImg}
                        alt={site.siteMetadata.title}
                        width="80px"
                      />
                    </a>
                  </div>
                  <div className="text-md-right mt-1">
                    &copy;
                    {formatMessage(
                      { id: "footer.copyright" },
                      {
                        title: site.siteMetadata.copyrightTitle,
                        year: new Date().getFullYear(),
                      }
                    )}
                  </div>
                </div>
              </>
            )}
          </Container>
        </Section>
      </footer>
    );
  }
);

export default Footer;
